import React, { useRef, useEffect } from 'react';
import { AmplifySignOut } from '@aws-amplify/ui-react';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import { makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import { Link } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';

const useStyles = makeStyles((theme) => ({
    formControl: {
        minWidth: 150,
    },
}));
const Header = (props) => {
    const classes = useStyles();
    const [select, setSelect] = React.useState('');
    const handleChange = (event) => {
        setSelect(event.target.value);
    };
    return (<div>
        <nav className="navbar navbar-light bg-light">
            <a className="navbar-brand" href="/">FORCEMON | RCE.ai</a>
            <div className="" id="navbarSupportedContent" className="d-flex align-items-center">

                <Dropdown className="mx-4" alignRight>
                    <Dropdown.Toggle variant="success" id="dropdown-basic">
                        <span className="mr-4">Menu</span>
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                       <Dropdown.Item><Link to="/ecg" className='text-dark text-decoration-none'>ECG Layout</Link></Dropdown.Item>
                       <Dropdown.Item><Link to="/devicelist" className='text-dark text-decoration-none'>Device Lists</Link></Dropdown.Item>
                       <Dropdown.Item><Link to="/tropsensor" className='text-dark text-decoration-none'>Trop Sensor</Link></Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>

                <AmplifySignOut />
               
            </div>
        </nav>
    </div>);


}
export default Header;
