import React from 'react';
import './App.css';
import Amplify, { Auth } from 'aws-amplify';
import config from "./config.json";
import { AmplifyAuthenticator, AmplifySignUp, AmplifySignIn, AmplifySignOut } from '@aws-amplify/ui-react';
import { AuthState, onAuthUIStateChange } from '@aws-amplify/ui-components';
import ECG from './components/ECG';
import axios from 'axios';
import DeviceList from './components/deviceList';
import TropSensor from './components/tropSensor';
import Header from './components/header';
import { BrowserRouter, Route, Switch, Link } from 'react-router-dom';


Amplify.configure({
  Auth: {
    region: config["cognito-region"],
    userPoolId: config["cognito-pool-id"],
    userPoolWebClientId: config["cognito-client-id"]
  }
});

Auth.configure();

const AuthStateApp = () => {
  const [authState, setAuthState] = React.useState();
  const [user, setUser] = React.useState();
  const [token, setToken] = React.useState();



  React.useEffect(() => {
    return onAuthUIStateChange((nextAuthState, authData) => {
      setAuthState(nextAuthState);
      setUser(authData)
      Auth.currentSession().then(data => {
        console.log(data)
        setToken(data.idToken.jwtToken);
      });
    });
  }, []);

 

  return authState === AuthState.SignedIn && user ? (
    <div className="App">
      { token && (

        <div>


          <BrowserRouter>

            <Header />
            <Switch>

              <Route exact path='/'
                render={(props) => (<DeviceList token={token} {...props} />)}
              />
              <Route exact path='/ecg'
                render={(props) => (<ECG token={token} {...props} />)}
              />
              <Route exact path='/devicelist'
                render={(props) => (<DeviceList token={token} {...props} />)}
              />
              <Route exact path='/tropsensor'
                render={(props) => (<TropSensor token={token} {...props} />)}
              />

            </Switch>

          </BrowserRouter>
        </div>
      )}
    </div>
  ) : (
    <AmplifyAuthenticator usernameAlias="email">
      <AmplifySignUp
        slot="sign-up"
        formFields={[
          { type: "email" },
          { type: "password" },
        ]}
      />
      <AmplifySignIn
        slot="sign-in"
        formFields={[
          { type: "email" },
          { type: "password" },
        ]}
      />
    </AmplifyAuthenticator>
  );
}

export default AuthStateApp;