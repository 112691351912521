import React, { useRef, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import axios from 'axios';
import config from "../config.json";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

const useStyles = makeStyles((theme) => ({
    formControl: {
        minWidth: 150,
    },
    table: {
        minWidth: 650,
    },
    
}));
const DeviceList = (props) => {
    const classes = useStyles();
    const [allDevices, setAllDevices] = React.useState();
    const [error, setError] = React.useState(false);
    const [errorMessage, setErrorMessage] = React.useState(false);
    useEffect(() => {
        if (props) {
            getDevice();
        }
    }, []);

    const getDevice = async () => {
        try {
            let url = config['path_url'] + "/devices" + "?trop=true";
            const { data } = await axios.get(url, { headers: { 'Authorization': props.token } });
            console.log(data);
            setAllDevices(data);
        }
        catch (error) {
            console.log(error.response.data.message);
            setErrorMessage(error.response.data.message);
            setError(true);
        }
    };

    return (<div className="m-3">
        <h3>Devices List</h3>
        <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell>Device UUID</TableCell>
                        <TableCell>IsTrop</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {allDevices && allDevices.result.map((row) => (
                        <TableRow key={row.id}>
                            <TableCell component="th" scope="row">
                                {row.udid}
                            </TableCell>
                            <TableCell component="th" scope="row">
                                {row.isTrop ? 'true': 'false' }
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>

    </div>);


}
export default DeviceList;
