import React, { useRef, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormLabel from '@material-ui/core/FormLabel';
import axios from 'axios';
import config from "../config.json";
import Dygraph from 'dygraphs';
import { readString, readRemoteFile } from 'react-papaparse'
import 'dygraphs/dist/dygraph.min.css';
import Header from './header';
import { data } from 'jquery';
import timezones from 'timezones-list';
var moment = require('moment-timezone');

const useStyles = makeStyles((theme) => ({
    formControl: {
        minWidth: 120,
        margin: '10px'
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));


const TropSensor = (props) => {

    const classes = useStyles();

    const [allDevices, setAllDevices] = React.useState();
    const [allYears, setAllYears] = React.useState();
    const [allMonths, setAllMonths] = React.useState();
    const [allDays, setAllDays] = React.useState();
    const [allPaths, setAllPaths] = React.useState();
    const [device, setDevice] = React.useState('');
    const [year, setYear] = React.useState('');
    const [month, setMonth] = React.useState('');
    const [day, setDay] = React.useState('');
    const [path, setPath] = React.useState('');
    const [filter, setFilter] = React.useState('filtered');
    const [liveReload, setLiveReload] = React.useState(false);
    const [rawData, setRawData] = React.useState('');
    const [delineate, setDelineate] = React.useState('');
    const [error, setError] = React.useState(false);
    const [errorMessage, setErrorMessage] = React.useState(false);
    const [timeZone, setTimeZone] = React.useState('');


    useEffect(() => {

        if (props) {
            getDevice();
            getLocation();
            console.log(timezones)
        }



    }, []);

    const getLocation = async () => {
        try {
            let url = "https://ipapi.co/json/";
            const { data } = await axios.get(url, { headers: { 'Authorization': props.token } });
            console.log(data.timezone);
            setTimeZone(data.timezone)
        }
        catch (error) {
            console.log(error.response.data.message);
            setErrorMessage(error.response.data.message);
            setError(true);
        }
    };
    const getDevice = async () => {
        try {
            let url = config['path_url'] + "/devices?trop=true&limit=100";
            const { data } = await axios.get(url, { headers: { 'Authorization': props.token } });
            console.log(data);
            setAllDevices(data);
            setDevice(data.result[0].id);
            getDeviceYear(data.result[0].id)
        }
        catch (error) {
            console.log(error.response.data.message);
            setErrorMessage(error.response.data.message);
            setError(true);
        }
    };
    const getDeviceYear = async (device_id) => {
        try {
            let url = config['path_url'] + "/device/" + device_id + "/log";
            const { data } = await axios.get(url, { headers: { 'Authorization': props.token } });
            console.log(data);
            setAllYears(data);
            setYear(data.years[data.years.length - 1]);
            getDeviceMonth(device_id, data.years[data.years.length - 1]);
            setError(false);
        }
        catch (error) {
            console.log(error.response.data.Error);
            setErrorMessage(error.response.data.Error);
            setError(true);
        }
    };
    const getDeviceMonth = async (device_id, device_year) => {

        try {
            let url = config['path_url'] + "/device/" + device_id + "/log?year=" + device_year;
            const { data } = await axios.get(url, { headers: { 'Authorization': props.token } });
            console.log(data);
            setAllMonths(data);
            setMonth(data.months[data.months.length - 1]);
            getDeviceDays(device_id, device_year, data.months[data.months.length - 1]);
            setError(false);
        }
        catch (error) {
            console.log("Error");
            setErrorMessage(error.response.data.Error);
            setError(true);
        }
    };
    const getDeviceDays = async (device_id, device_year, device_month) => {

        try {
            let url = config['path_url'] + "/device/" + device_id + "/log?year=" + device_year + "&month=" + device_month;
            const { data } = await axios.get(url, { headers: { 'Authorization': props.token } });
            console.log(data);
            setAllDays(data);
            setDay(data.days[data.days.length - 1]);
            getDevicePaths(device_id, device_year, device_month, data.days[data.days.length - 1]);
            setError(false);
        }
        catch (error) {
            console.log("Error");
            setErrorMessage(error.response.data.Error);
            setError(true);
        }
    };
    const getDevicePaths = async (device_id, device_year, device_month, device_day) => {

        try {
            let url = config['path_url'] + "/device/" + device_id + "/log?year=" + device_year + "&month=" + device_month + "&day=" + device_day + "&limit=2000";
            const { data } = await axios.get(url, { headers: { 'Authorization': props.token } });
            console.log(data);
            setAllPaths(data);
            setPath(data.paths[data.paths.length - 1]);
            setError(false);
        }
        catch (error) {
            console.log("Error");
            setErrorMessage(error.response.data.Error);
            setError(true);
        }
    };
    const getShortPath = (item) => {
        let path12 = item.split('/').pop().split('-');
        return (path12[0] + "-" + path12[1]);
    }

    const handleDeviceChange = async (event) => {
        console.log(event.target.value)
        setDevice(event.target.value);
        getDeviceYear(event.target.value)
    };

    const handleYearChange = (event) => {
        console.log(event.target.value)
        setYear(event.target.value);
        getDeviceMonth(device, event.target.value)
    };
    const handleMonthChange = (event) => {
        console.log(event.target.value)
        setMonth(event.target.value);
        getDeviceDays(device, year, event.target.value)
    };
    const handleDayChange = (event) => {
        console.log(event.target.value)
        setDay(event.target.value);
        getDevicePaths(device, year, month, event.target.value)
    };
    const handlePathChange = (event) => {
        console.log(event.target.value)
        setPath(event.target.value)
    };
    const handleChange = (event) => {
        console.log(event.target.checked)
        setLiveReload(event.target.checked);
    };
    const getUtcTime = (year, month, day, path) => {
        let time = getShortPath(path);
        let hours = time ? time.split('-')[0] : 0;
        let minutes = time ? time.split('-')[1] : 0;
        // const utcDate = new Date(Date.UTC(year, (month - 1), (day), hours, minutes));
        let utcDate = new Date(year, (month - 1), (day), hours, minutes);
        let data = utcDate.toUTCString();
        data = data.split(' ').slice(0, 5).join(' ');

        return data;
    }

    const getGmtTime = (year, month, day, path) => {

        // let time = getShortPath(path);
        // let hours = time ? time.split('-')[0] : 0;
        // let minutes = time ? time.split('-')[1] : 0;
        // let utcDate = new Date(year, (month - 1), (day), hours, minutes);
        // var data = moment.tz(utcDate, timeZone.split(' ')[0]).toString();

        // return data;
    }

    const handleTimeZonesChange = async (e) => {
        console.log(e.target.value)
        setTimeZone(e.target.value);
    }
    const getUtc = (value) => {
        let timedate = value.split('/');
        let time = getShortPath(timedate[5]);
        let hours = time ? time.split('-')[0] : 0;
        let minutes = time ? time.split('-')[1] : 0;
        // let utcDate = new Date(timedate[2], (timedate[3] - 1), timedate[4], hours, minutes);
        // let data = utcDate.toUTCString();
        // data = data.split(' ').slice(0, 5).join(' ');

        let now = timedate[2] + '-' + (timedate[3]) + '-' + timedate[4] + " " + hours + ':' + minutes;
        // var data = moment.tz(now, timeZone).utc().format('MMM DD YYYY HH:mm');
        var data = moment.tz(now, timeZone).format('MMM DD YYYY HH:mm');
        

        return data;
    }

    return (<div>
        <div>
            <div className="alert-box">
                <div className="alert alert-success" style={{ display: 'none' }}>
                    <strong>Success!</strong> call Succeeded!
                </div>
                <div className="alert alert-danger" style={{ display: !error ? 'none' : 'block' }}>
                    <strong>Error!</strong> {errorMessage}
                </div>
            </div>

            <div className="container-fluid">

                <div className="controls mt-3">

                    <InputLabel>Device:</InputLabel>
                    <FormControl className={classes.formControl}>
                        <InputLabel>Device</InputLabel>
                        <Select
                            value={device}
                            onChange={handleDeviceChange}
                        >
                            {allDevices && allDevices.result.map((item) => (
                                <MenuItem key={item.id} value={item.id}>{item.udid}</MenuItem>
                            ))}

                        </Select>
                    </FormControl>

                    {/* <div style={{ margin: '20px 0px' }}>
                        <InputLabel>Date / Time:</InputLabel>

                        <FormControl className={classes.formControl}>
                            <InputLabel>Device year</InputLabel>
                            <Select
                                value={year}
                                onChange={handleYearChange}
                            >
                                {allYears && allYears.years.map((item) => (
                                    <MenuItem key={item} value={item}>{item}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>

                        <FormControl className={classes.formControl}>
                            <InputLabel>Device month</InputLabel>
                            <Select
                                value={month}
                                onChange={handleMonthChange}
                            >
                                {allMonths && allMonths.months.map((item) => (
                                    <MenuItem key={item} value={item}>{item}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>

                        <FormControl className={classes.formControl}>
                            <InputLabel>Device date</InputLabel>
                            <Select
                                value={day}
                                onChange={handleDayChange}
                            >
                                {allDays && allDays.days.map((item) => (
                                    <MenuItem key={item} value={item}>{item}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <FormControl className={classes.formControl}>
                            <InputLabel>Device path</InputLabel>
                            <Select
                                value={path}
                                onChange={handlePathChange}
                            >
                                {allPaths && allPaths.paths.map((item) => (
                                    <MenuItem key={item} value={item}>{getShortPath(item)}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <FormControl className={classes.formControl}>
                            <FormControlLabel style={{ marginTop: '10px' }}
                                control={
                                    <Checkbox
                                        checked={liveReload}
                                        onChange={handleChange}
                                        name="checkedB"
                                        color="primary"
                                    />
                                }
                                label="Live Reload"
                            />
                        </FormControl>
                    </div> */}

                    <div>
                        <FormControl className={classes.formControl}>
                            <InputLabel>Device path</InputLabel>
                            <Select
                                value={path}
                                onChange={handlePathChange}
                            >
                                {allPaths && allPaths.paths.map((item) => (
                                    <MenuItem key={item} value={item}>{getUtc(item)}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>

                        <FormControl className={classes.formControl}>
                            <InputLabel>Time Zones</InputLabel>

                            <Select
                                value={timeZone}
                                onChange={handleTimeZonesChange}
                                disabled
                            >
                                {timezones && timezones.map((item) => (
                                    <MenuItem key={item.tzCode} value={item.tzCode}>{item.tzCode}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </div>
                    {/* <div className='d-flex flex-column align-items-start m-5'>
                        {path && <p>UTC TIME: {getUtcTime(year,month,day,path)}</p>}
                        {path && <p>GMT TIME: {getGmtTime(year,month,day,path)}</p>}  
                    </div>         */}
                </div>

            </div>

        </div>

    </div>);
}

export default TropSensor;
