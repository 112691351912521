import React, { useRef, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormLabel from '@material-ui/core/FormLabel';
import axios from 'axios';
import config from "../config.json";
import Dygraph from 'dygraphs';
import { readString, readRemoteFile } from 'react-papaparse'
import 'dygraphs/dist/dygraph.min.css';
import Header from './header';
const useStyles = makeStyles((theme) => ({
    formControl: {
        minWidth: 120,
        margin: '10px'
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));


const ECG = (props) => {
    
    const classes = useStyles();
    
    const [allDevices, setAllDevices] = React.useState();
    const [allYears, setAllYears] = React.useState();
    const [allMonths, setAllMonths] = React.useState();
    const [allDays, setAllDays] = React.useState();
    const [allPaths, setAllPaths] = React.useState();
    const [device, setDevice] = React.useState('');
    const [year, setYear] = React.useState('');
    const [month, setMonth] = React.useState('');
    const [day, setDay] = React.useState('');
    const [path, setPath] = React.useState('');
    const [filter, setFilter] = React.useState('filtered');
    const [liveReload, setLiveReload] = React.useState(false);
    const [rawData, setRawData] = React.useState('');
    const [delineate, setDelineate] = React.useState('');
    const [error, setError] = React.useState(false);
    const [errorMessage, setErrorMessage] = React.useState(false);



    useEffect(() => {

        if(props){
            getDevice();
        }

        drawdygraph();

        
    }, []);

    

    const drawdygraph = () => {
         window.g = [];

        var labelarr = ['V6', 'I', 'II', 'V2', 'V3', 'V4', 'V5', 'V1', 'III', 'AVL', 'AVF', 'AVR'];
        for (var ch = 1; ch <= 12; ch++) {
            var data = [[0, 0]]
            var gr = new Dygraph(
                document.getElementById("graph" + ch),
                data,
                {
                    'axisLineColor': 'rgb(123,0,0)',
                    //title: labelarr[ch - 1],
                    //titleHeight: 16,
                    ylabel: labelarr[ch - 1],
                    yLabelWidth: 12,
                    axisLabelWidth: 18,
                    axes: {
                        x: {
                            drawAxis: false
                        },
                        y: {
                            drawGrid: false
                        }
                    },
                    showLabelsOnHighlight: false,
                    color: '#000',
                    labels: ['X', labelarr[ch - 1]],
                    drawGrid: false,
                    underlayCallback: function (canvas, area, g) {

                        //draw y minor axis
                        for (var i = 0; i <= 2400; i += 8) {
                            var tick = i;
                            const x = g.toDomXCoord(tick);
                            //canvas.fillRect(x, area.y, x, area.h);
                            canvas.strokeStyle = "#F2BDBAAA";

                            canvas.beginPath();
                            canvas.moveTo(x, area.y);
                            canvas.lineTo(x, area.h);
                            canvas.stroke();
                        }
                        //draw x minor axis
                        for (var i = area.y; i <= area.h; i += 10) {
                            canvas.strokeStyle = "#F2BDBAAA";
                            var x = g.toDomXCoord(0);
                            var x2 = g.toDomXCoord(2400);
                            canvas.beginPath();
                            canvas.moveTo(x, i);
                            canvas.lineTo(x2, i);
                            canvas.stroke();
                        }
                        //draw y major axis
                        for (var i = 0; i <= 2400; i += 40) {
                            var tick = i;
                            const x = g.toDomXCoord(tick);
                            //canvas.fillRect(x, area.y, x, area.h);
                            canvas.strokeStyle = "#F58F8F";
 
                            canvas.beginPath();
                            canvas.moveTo(x, area.y);
                            canvas.lineTo(x, area.h);
                            canvas.stroke();

                        }
                        //draw x major axis
                        for (var i = area.y; i <= area.h; i += 50) {
                            canvas.strokeStyle = "#F58F8F";
                            var x = g.toDomXCoord(0);
                            var x2 = g.toDomXCoord(2400);
                            canvas.beginPath();
                            canvas.moveTo(x, i);
                            canvas.lineTo(x2, i);
                            canvas.stroke();
                        }
                    }//underlayCallback
                }//dict
            );//dygraphs

            window.g.push(gr);
        }
       
    }
    
    const getDevice = async () => {
        try {
            let url = config['path_url'] + "/devices?limit=1000";
            const { data } = await axios.get(url, { headers: { 'Authorization': props.token } });
            console.log(data);
            setAllDevices(data);
            setDevice(data.result[0].id);
            getDeviceYear(data.result[0].id)
        }
        catch (error) {
            console.log(error.response.data.message);
            setErrorMessage(error.response.data.message);
            setError(true);
        }
    };
    const getDeviceYear = async (device_id) => {
        try {
            let url = config['path_url'] + "/device/"+ device_id + "/log";
            const { data } = await axios.get(url, { headers: { 'Authorization': props.token } });
            console.log(data);
            setAllYears(data);
            setYear(data.years);
            getDeviceMonth(device_id,data.years[0]);
            setError(false);
        }
        catch (error) {
            console.log(error.response.data.Error);
            setErrorMessage(error.response.data.Error);
            setError(true);
        }
    };
    const getDeviceMonth = async (device_id,device_year) => {

        try {
            let url = config['path_url'] + "/device/"+ device_id + "/log?year=" + device_year;
            const { data } = await axios.get(url, { headers: { 'Authorization': props.token } });
            console.log(data);
            setAllMonths(data);
            setMonth(data.months[0]);
            getDeviceDays(device_id,device_year,data.months[0]);
            setError(false);
        }
        catch (error) {
            console.log("Error");
            setErrorMessage(error.response.data.Error);
            setError(true);
        }
    };
    const getDeviceDays = async (device_id,device_year,device_month) => {

        try {
            let url = config['path_url'] + "/device/"+ device_id + "/log?year=" + device_year+ "&month=" + device_month;
            const { data } = await axios.get(url, { headers: { 'Authorization': props.token } });
            console.log(data);
            setAllDays(data);
            setDay(data.days[0]);
            getDevicePaths(device_id,device_year,device_month,data.days[0]);
            setError(false);
        }
        catch (error) {
            console.log("Error");
            setErrorMessage(error.response.data.Error);
            setError(true);
        }
    };
    const getDevicePaths = async (device_id,device_year,device_month,device_day) => {

        try {
            let url = config['path_url'] + "/device/"+ device_id + "/log?year=" + device_year+ "&month=" + device_month + "&day=" + device_day + "&limit=2000";
            const { data } = await axios.get(url, { headers: { 'Authorization': props.token } });
            console.log(data);
            setAllPaths(data);
            setPath(data.paths[0]);
            reloadGraphs(data.paths[0])
            setError(false);
        }
        catch (error) {
            console.log("Error");
            setErrorMessage(error.response.data.Error);
            setError(true);
        }
    };
    const getShortPath = (item) => {
        let path12 = item.split('/').pop().split('-');
        return (path12[0] + "-" + path12[1]);
    }
    const reloadGraphs = async (device_path) => {

        try {
            let url = config['path_url'] + "/log?path=" + device_path;
        
        const { data } = await axios.get(url, { headers: { "Accept": "text/csv",'Authorization': props.token } });
        var res = readString(data);
            setRawData(data);
            delineateData(data);
            if(filter == 'raw'){
                processData(res);  
            }else{
                filterGraph(data);
            }
            setError(false);
        }
        catch (error) {
            console.log("Error");
            setErrorMessage(error.response.data.Error);
            setError(true);
        }
    };

    const filterGraph = async (info) => {
            try {
                let url = config['path_url'] + "/filter";
                const { data } = await axios.post(url,info, { headers: { "Content-Type": "text/csv",'Authorization': props.token } });
                console.log(data);
                var res = readString(data);
                processData(res);  
                setError(false);
            }
            catch (error) {
                console.log("Error");
                setErrorMessage(error.response.data.Error);
                setError(true);
            }
    }

    const delineateData = async (info) => {
            
            try {
                let url = config['path_url'] + "/delineate";
                const { data } = await axios.post(url,info, { headers: { "Content-Type": "text/csv",'Authorization': props.token } });
                console.log(data);

                setDelineate(data);
                setError(false);
            }
            catch (error) {
                console.log("Error");
                setErrorMessage(error.response.data.Error);
                setError(true);
            }

    }


    const processData = async (dat) => {
        console.log("before");
        console.log(dat);
        var res = dat.data;
        res.shift();
        var data = [];
        for (var ch = 1; ch <= 8; ch++) {
            data = [];
            for (var i = 0; i < res.length - 1; i++) {
                data.push([parseInt(res[i][0]), parseFloat(res[i][ch])]);
            }
            window.g[ch - 1].updateOptions({ 'file': data });
            console.log("channel:", ch-1);
            console.log(data);
        }
        console.log(window.g)

        // lead3    = channel3-channel2

        data = [];
        for (var i = 0; i < res.length - 1; i++) {
            data.push([parseInt(res[i][0]), parseFloat(res[i][3]) - parseFloat(res[i][2])]);
        }
        window.g[8].updateOptions({ 'file': data });
        var lead3 = data.slice();

        //     avl      = (channel1-lead3)/2

        data = [];
        for (var i = 0; i < res.length - 1; i++) {
            data.push([parseInt(res[i][0]), (parseFloat(res[i][1]) - lead3[i][1]) / 2.0]);
        }
        window.g[9].updateOptions({ 'file': data });

        //     avf      = (channel2+lead3)/2
        data = [];
        for (var i = 0; i < res.length - 1; i++) {
            data.push([parseInt(res[i][0]), (parseFloat(res[i][2]) + lead3[i][1]) / 2.0]);
        }
        window.g[10].updateOptions({ 'file': data });

        //     avr      = -1.0 *(channel1+channel2)/2
        data = [];
        for (var i = 0; i < res.length - 1; i++) {
            data.push([parseInt(res[i][0]), -1.0 * (parseFloat(res[i][1]) + parseFloat(res[i][2])) / 2.0]);
        }
        window.g[11].updateOptions({ 'file': data });  
    }

    const handleDeviceChange = async (event) => {
        console.log(event.target.value)
        setDevice(event.target.value);
        getDeviceYear(event.target.value)
    };

    const handleYearChange = (event) => {
        console.log(event.target.value)
        setYear(event.target.value);
        getDeviceMonth(device,event.target.value)
    };
    const handleMonthChange = (event) => {
        console.log(event.target.value)
        setMonth(event.target.value);
        getDeviceDays(device,year,event.target.value)
    };
    const handleDayChange = (event) => {
        console.log(event.target.value)
        setDay(event.target.value);
        getDevicePaths(device,year,month,event.target.value)
    };
    const handlePathChange = (event) => {
        console.log(event.target.value)
        // let path12 = event.target.value.split('/').pop().split('-');
        // console.log(path12)
        // setPath(path12[0] + "-" + path12[1]);
        setPath(event.target.value)
    };
    const handleFilterChange = (event) => {
        console.log(event.target.value)
        setFilter(event.target.value);
        console.log(rawData)
        if(event.target.value == 'raw'){
            var res = readString(rawData);
            processData(res);
        }else{
            filterGraph(rawData);
        }

        
    };
    const handleChange = (event) => {
        console.log(event.target.checked)
        setLiveReload(event.target.checked);
        if(event.target.checked) {
            var intervalSecs = 5000;
            console.log("should auto reload every ", intervalSecs);
            
            if(window.liveIntervalId != null && window.liveIntervalId != undefined) {
                clearInterval(window.liveIntervalId);
            }

            window.liveIntervalId = setInterval(function() { getDevicePaths(device,year,month,day); }, intervalSecs);
        } else {
            console.log("stop autoreload");
            if(window.liveIntervalId != null && window.liveIntervalId != undefined) {
                clearInterval(window.liveIntervalId);
            }
        }
    };

    return (<div>
        <div>

            {/* <Header /> */}

        
            <div className="alert-box">
                <div className="alert alert-success" style={{ display: 'none' }}>
                    <strong>Success!</strong> call Succeeded!
                </div>
                <div className="alert alert-danger" style={{ display: !error ? 'none':'block' }}>
                    <strong>Error!</strong> { errorMessage }
                </div>
            </div>

            <div className="container-fluid">

                <div className="controls mt-3">

                    <InputLabel>Device:</InputLabel>
                    <FormControl className={classes.formControl}>
                        <InputLabel>Device</InputLabel>
                        <Select
                            value={device}
                            onChange={handleDeviceChange}
                        >
                            {allDevices && allDevices.result.map((item) => (
                                <MenuItem key={item.id} value={item.id}>{item.udid}</MenuItem>
                            ))}

                        </Select>
                    </FormControl>

                    <div style={{ margin: '20px 0px' }}>

                        <InputLabel>Date / Time:</InputLabel>

                        <FormControl className={classes.formControl}>
                            <InputLabel>Device year</InputLabel>
                            <Select
                                value={year}
                                onChange={handleYearChange}
                            >
                                {allYears && allYears.years.map((item) => (
                                    <MenuItem key={item} value={item}>{item}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>

                        <FormControl className={classes.formControl}>
                            <InputLabel>Device month</InputLabel>
                            <Select
                                value={month}
                                onChange={handleMonthChange}
                            >
                                {allMonths && allMonths.months.map((item) => (
                                    <MenuItem key={item} value={item}>{item}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>

                        <FormControl className={classes.formControl}>
                            <InputLabel>Device date</InputLabel>
                            <Select
                                value={day}
                                onChange={handleDayChange}
                            >
                                {allDays && allDays.days.map((item) => (
                                    <MenuItem key={item} value={item}>{item}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <FormControl className={classes.formControl}>
                            <InputLabel>Device path</InputLabel>
                            <Select
                                value={path}
                                onChange={handlePathChange}
                            >
                                 {allPaths && allPaths.paths.map((item) => (
                                    <MenuItem key={item} value={item}>{getShortPath(item)}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <FormControl className={classes.formControl}>
                            <FormControlLabel style={{ marginTop: '10px' }}
                                control={
                                    <Checkbox
                                        checked={liveReload}
                                        onChange={handleChange}
                                        name="checkedB"
                                        color="primary"
                                    />
                                }
                                label="Live Reload"
                            />
                        </FormControl>
                    </div>


                    <div className="control">

                        <FormControl component="fieldset" style={{ display: 'flex' }}>
                            <FormLabel component="legend">Filter:</FormLabel>
                            <RadioGroup aria-label="filter" name="filter" value={filter} onChange={handleFilterChange} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                                <FormControlLabel value="filtered" control={<Radio />} label="Filtered" />
                                <FormControlLabel value="raw" control={<Radio />} label="Raw" />
                            </RadioGroup>
                        </FormControl>

                        <div style={{ display: 'flex' }}>
                            <FormControl component="fieldset" style={{ margin:'0px 10px' }}>
                                <FormLabel component="legend">QRS:</FormLabel>
                                <span>{ delineate.QRS ? delineate.QRS.toFixed(2) : '-' }</span>
                            </FormControl>
                            <FormControl component="fieldset" style={{ margin:'0px 10px' }}>
                                <FormLabel component="legend">QT:</FormLabel>
                                <span>{ delineate.QT ? delineate.QT.toFixed(2) : '-'}</span>
                            </FormControl>
                            <FormControl component="fieldset" style={{ margin:'0px 10px' }}>
                                <FormLabel component="legend">QTC:</FormLabel>
                                <span>{ delineate.QTC ? delineate.QTC.toFixed(2) : '-' }</span>
                            </FormControl>
                            <FormControl component="fieldset" style={{ margin:'0px 10px' }}>
                                <FormLabel component="legend">RR:</FormLabel>
                                <span>{ delineate.RR ? delineate.RR.toFixed(2) : '-' }</span>
                            </FormControl>
                        </div>
                        
                    </div>
                </div>

                <div style={{margin:'40px 40px 40px 0px'}}>

                    <div className="row my-1">
                        <div className="graph col-sm-3" id="graph2"></div>
                        <div className="graph col-sm-3" id="graph12"></div>
                        <div className="graph col-sm-3" id="graph8"></div>
                        <div className="graph col-sm-3" id="graph6"></div>
                    </div>

                    <div className="row my-1">
                        <div className="graph col-sm-3" id="graph3"></div>
                        <div className="graph col-sm-3" id="graph10"></div>
                        <div className="graph col-sm-3" id="graph4"></div>
                        <div className="graph col-sm-3" id="graph7"></div>
                    </div>

                    <div className="row my-1">
                        <div className="graph col-sm-3" id="graph9"></div>
                        <div className="graph col-sm-3" id="graph11"></div>
                        <div className="graph col-sm-3" id="graph5"></div>
                        <div className="graph col-sm-3" id="graph1"></div>
                    </div>
                </div>

            </div>

        </div>
    
    </div>);
}

export default ECG;
